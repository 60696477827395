const officeMode = localStorage.getItem('isOfficeMode') || '';

const initialState = {
  currentUser: false,
  counter: 0,
  officeMode: officeMode === "true",
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_OFFICE_MODE':
      localStorage.setItem('isOfficeMode', !state.officeMode);
      return {
        ...state,
        officeMode: !state.officeMode,
      };
    case 'LOGIN_USER':
      return {
        ...state, 
        currentUser: action.payload ? {...state.currentUser, ...action.payload} : true
      };
    case 'LOGOUT_USER':
      localStorage.removeItem("auth-token");
      return {
        ...state, 
        currentUser: false 
      };
    case 'INCREASE_COUNTER':
      return {
        ...state, 
        counter: state.counter + 1 
      };
    case 'DECREASE_COUNTER':
      return {
        ...state, 
        counter: state.counter - 1 
      };
    default:
      return state;
  }
}

export default reducer;