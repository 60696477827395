import React, {Component} from 'react';
// import ReactTooltip from 'react-tooltip';
import './style.scss';

class Table extends Component {
    state = {
        loading: true
    }

    render() {
        // console.log('table render');
        const {heading, tableData, tableType} = this.props;

        if (!tableData) {
            return '';
        }

        return (
            <div className='statistik-table-holder'>
                <table className={`statistik-table ${tableType}`}>
                    {createTHead(heading, tableData.tableHeadings)}
                    <tbody>
                    {createTRows(tableData.data, tableType)}
                    </tbody>
                </table>
            </div>
        )
    }
}

function createTHead(title, colTitle) {
    return (
        <thead>
        <tr>
            <th colSpan={title.span}>{title.title}</th>
            {colTitle.map((item, i) => {
                return <th key={i + item} className={item.replace(' ', '').replace('.', '') + '-head'}>{item}</th>
            })}
        </tr>
        </thead>
    )
}

function createTRows(data, type) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
    const officesAssignedIn = currentUser?.offices?.map((office) => {
        return office.id;
    });

    switch (type) {
        case 'rookie_board':
        case 'logged_in_monthly':
        case 'board':
            return (
                <>
                    {data.map((item, i) => {
                        let cups = '', rocket = '', medal = '';

                        let dateHide = '';
                        // if (new Date().getDate() < 15) dateHide = ' hide ';

                        if (item.has_rocket) {
                            rocket += ' show rocket ' + dateHide;
                        }
                        if (item.has_bucket || item.cups > 0) {
                            cups += ' show cups ' + dateHide;
                        }
                        if (item.has_medal_meetings || item.medals > 0) {
                            medal += ' show cups ' + dateHide;
                        }
                        if (item.has_medal_sales || item.medals > 0) {
                            medal += ' show cups ' + dateHide;
                        }

                        const rank = item?.rank ? item.rank : i + 1;

                        return (
                            <tr key={i} data-agentid={item.id} className={item.id === currentUser.id ? "loggedInAgent" : ""}>
                                <td className="number">{rank}</td>
                                <td className="image">
                                    <div className="person-image" style={{ backgroundImage: "url(" + item.image + ")" }}></div>
                                </td>
                                <td className="person">
                                    {item.name}
                                    <strong className="person-office">
                                        {item.offices.map((office, index) => {
                                            const delimiter = index + 1 === item.offices.length ? '' : '/'
                                            return (<>
                                            {office.name.replace('Svenska Mäklarhuset', '')}{delimiter}
                                            </>)
                                        })}
                                    </strong>
                                </td>
                                <td className={'status ' + rocket}>
                                    {
                                        item.category !== "Rookie" && item.has_rocket &&
                                        <img src="https://live-backend.saljtavla.svenskamaklarhuset.se/rocket.svg" alt="Rocket" className="rocket-image" data-tip="Mest ökning jämfört med samma månad förra året!"/>
                                    }
                                </td>
                                <td className={'status '+cups}>
                                    {
                                        item.category !== "Rookie" && item.cups > 0 &&
                                        <div className="pokal-image" data-tip="Högst provision per månad"><img src="https://live-backend.saljtavla.svenskamaklarhuset.se/Pokal.png" alt="Pokal" className="pokal-image"/><span>{item.cups}</span></div>
                                    }
                                </td>
                                <td className={'status '+medal}>
                                    {
                                        item.category !== "Rookie" && item.medals > 0 &&
                                        <div className="pokal-image" data-tip="Flest antal möten, tips och försäljningar"><img src="https://live-backend.saljtavla.svenskamaklarhuset.se/Medalj.png" alt="Medalj" className="pokal-image"/><span>{item.medals}</span></div>
                                    }
                                </td>
                                <td className={item.has_grown_up ? 'commission' : 'commission'}
                                    data-tip={item.has_grown_up ? 'Bättre placering än igår!' : ''}>
                                    <span className={item.has_grown_up ? 'commission arrow' : 'commission'}></span>
                                    {item.cur_month_commission ? numberWithSpaces(item.cur_month_commission) + '\u00A0' + 'kr' : '-'}</td>
                                <td className="budget">{item.budget_percentage ? (parseInt(item.budget_percentage) + '%') : '-'}</td>
                                <td className="soldEstateParts">{item.sold_estates ? item.sold_estates : '-'}</td>
                                <td className="möten">{item.meetings_count ? item.meetings_count : '-'}</td>

                                <td className="prev-month-commission">{item.last_year_commission ? numberWithSpaces(item.last_year_commission) + '\u00A0' + 'kr' : '-'}</td>
                            </tr>
                        )
                    })}
                </>
            );
        case 'single_office_data':
            var sum_meetings = 0;
            var sum_newAssignments = 0;
            var sum_soldEstateParts = 0;
            var sum_budget = 0;
            var sum_commission = 0;
            var sum_tillsalu = 0;
            var sum_kommande = 0;
            var sum_viewings = 0;
            var sum_tips = 0;
            var currencyCode = 'kr';
            return (
                <>
                    {data.users.map((item, i) => {
                        // console.log('item',item)
                        sum_meetings += parseInt(item.meetings_count);
                        sum_newAssignments += parseInt(item.activityTranslated.new_assignments);
                        sum_soldEstateParts += parseInt(item.sales);
                        sum_budget += parseInt(item.budget);
                        sum_commission += item.commission;
                        sum_tillsalu += parseInt(item.activityTranslated.in_selling);
                        sum_kommande += parseInt(item.activityTranslated.future);
                        sum_viewings += parseInt(item.activityTranslated.viewings);
                        sum_tips += parseInt(item.activityTranslated.tips_sent);
                        currencyCode = 'kr';
                        return (
                            <tr key={i} data-agentid={item.id} className={item.id === currentUser.id ? "loggedInAgent" : ""}>
                                <td className="number">{i + 1}</td>
                                <td className="image">
                                    <div className="person-image" style={{ backgroundImage: "url(" + item.image + ")" }}></div>
                                </td>
                                <td className="person">
                                    {item.name}
                                </td>
                                {/*<td className={item.rocket ? 'status rocket' : 'status'}>*/}
                                {/*    <img src="https://phpstack-724978-2699069.cloudwaysapps.com/rocket.svg" alt="Rocket" className="rocket-image" data-tip="Mest ökning jämfört med samma månad förra året!" />*/}
                                {/*</td>*/}

                                <td className={item.has_grown_up ? 'commission ' : 'commission '}  data-budget={item.budget}  style={{fontWeight: 400}}
                                    data-tip={item.has_grown_up ? 'Bättre placering än igår!' : ''}>
                                    <span className={item.has_grown_up ? 'commission arrow' : 'commission'}></span>
                                    {item.commission ? numberWithSpaces(item.commission) + '\u00A0' + "kr" : '-'}</td>
                                <td className="möten">{item.budget ? numberWithSpaces(item.budget) + '\u00A0' + "kr" : '-'}</td>
                                <td className="möten">{item.meetings_count ? item.meetings_count : '-'}</td>
                                <td className="möten">{item.activityTranslated.tips_sent ? item.activityTranslated.tips_sent : '-'}</td>
                                <td className="möten">{item.activityTranslated.new_assignments ? item.activityTranslated.new_assignments : '-'}</td>
                                {/*<td className="möten">{item.tips ? item.tips : '-'}</td>*/}
                                <td className="soldEstateParts">{item.sales ? item.sales : '-'}</td>
                                <td className="tillsalu">{(item.activityTranslated.in_selling && item.activityTranslated.in_selling != '0') ? item.activityTranslated.in_selling : '-'}</td>
                                <td className="kommande">{(item.activityTranslated.future && item.activityTranslated.future != '0') ? item.activityTranslated.future : '-'}</td>
                                <td className="viewings">{(item.activityTranslated.viewings && item.activityTranslated.viewings != '0') ? item.activityTranslated.viewings : '-'}</td>
                                {/*<td className={"tooltipwrapper"}><ReactTooltip/></td>*/}
                            </tr>
                        )
                    })}
                    <tr key={999} style={{fontWeight: 600}}>
                        <td className="number">Total:</td>
                        <td className="image"></td>
                        <td className="person"></td>
                        <td className={'commission'}>{sum_commission ? numberWithSpaces(sum_commission) + '\u00A0' + currencyCode : '-'}</td>
                        <td className={'sum_budget total-hide-mob'}>{sum_budget ? numberWithSpaces(sum_budget) + '\u00A0' + currencyCode : '-'}</td>
                        <td className="möten total-hide-mob">{sum_meetings ? sum_meetings : '-'}</td>
                        <td className="möten total-hide-mob">{sum_tips ? sum_tips : '-'}</td>
                        <td className="möten total-hide-mob">{sum_newAssignments ? sum_newAssignments : '-'}</td>
                        <td className="soldEstateParts total-hide-mob">{sum_soldEstateParts ? sum_soldEstateParts : '-'}</td>
                        <td className="soldEstateParts">{sum_tillsalu ? sum_tillsalu : '-'}</td>
                        <td className="soldEstateParts total-hide-mob">{sum_kommande ? sum_kommande : '-'}</td>
                        <td className="soldEstateParts total-hide-mob">{sum_viewings ? sum_viewings : '-'}</td>
                        {/*<td className={"tooltipwrapper"}></td>*/}
                    </tr>
                </>
            );
        case 'office_board':
            let dateHide = '';
            if (new Date().getDate() < 15) dateHide = ' hide ';
            return (
                <>
                    {data.map((item, i) => {
                        return (
                            <tr key={i} data-agentid={item.id} className={officesAssignedIn.includes(item.id) ? "loggedInAgent" : ""}>
                                <td className="number">{i + 1}</td>

                                <td className="person">
                                    {item.name.replace('Svenska Mäklarhuset', '')}
                                </td>
                                {/*<td className={item.rocket ? 'status rocket' : 'status'}>*/}
                                <td className={item.has_rocket ? 'status rocket'+dateHide : 'status'}>
                                    <img src="https://live-backend.saljtavla.svenskamaklarhuset.se/rocket.svg" alt="Rocket"
                                         className="rocket-image"
                                         data-tip="Mest ökning jämfört med samma månad förra året!"/>
                                </td>
                                <td className={item.has_grown_up ? 'commission' : 'commission'}
                                    data-tip={item.has_grown_up ? 'Bättre placering än igår!' : ''}>
                                    <span className={item.has_grown_up ? 'commission arrow' : 'commission'}></span>
                                    {item.commission ? numberWithSpaces(item.commission) + '\u00A0' + 'kr' : '-'}
                                </td>
                                <td className="budget" data-budget={item.current_month_office_budget}>{item.current_month_office_budget ? (Math.ceil((parseInt(item.commission) / parseInt(item.current_month_office_budget)) * 100) + '%') : '-'}</td>
                                <td className="soldEstateParts">{item.sold ? parseFloat(item.sold) : '-'}</td>
                                <td className="möten">{item.meetings_count ? item.meetings_count : '-'}</td>
                                {/*<td className="budget">{item.budget ? item.budget + '%' : '-'}</td>*/}
                                <td className="prev-month-commission">{item.last_year_commission ? numberWithSpaces(item.last_year_commission) + '\u00A0' + 'kr' : '-'}</td>
                            </tr>
                        )
                    })}
                </>
            );
        case 'office_leader_board':
            return (
                <>
                    {data.map((item, i) => {
                        return (
                            <tr key={i} data-agentid={item.id} test="da" className={officesAssignedIn.includes(item.id) ? "loggedInAgent" : ""}>
                                <td className="number">{i + 1}</td>

                                <td className="person">
                                    {item.name}
                                </td>
                                <td className="commission">{item.commission ? numberWithSpaces(item.commission) + '\u00A0' + 'kr' : '-'}</td>
                            </tr>
                        )
                    })}
                </>
            );
        case 'rookie_leader_board':
        case 'logged_in_annual':
        case 'leader_board':

            return (
                <>
                    {data.map((item, i) => {
                        const rank = item?.rank ? item.rank : i + 1;

                        return (
                            <tr key={i} data-agentid={item.id} className={item.id === currentUser.id ? "loggedInAgent" : ""}>
                                <td className="number">{rank}</td>
                                <td className="image">
                                    <div className="person-image" style={{ backgroundImage: "url(" + item.image + ")" }}></div>
                                </td>
                                <td className="person">
                                    {item.name}
                                </td>
                                <td className="commission">{item.cur_month_commission ? numberWithSpaces(item.cur_month_commission) + '\u00A0' + 'kr' : '-'}</td>
                            </tr>
                        )
                    })}
                </>
            );
        case 'agent_tips_board':
            return (
                <>
                    {data.map((item, i) => {
                        return (
                            <tr key={i} data-agentid={item.id} className={item.id === currentUser.id ? "loggedInAgent" : ""}>
                                <td className="number">{i + 1}</td>
                                <td className="image">
                                    <div className="person-image" style={{ backgroundImage: "url(" + item.image + ")" }}></div>
                                </td>
                                <td className="person">
                                    {item.name}
                                    <strong className="person-office">
                                        {item.offices.map((office, index) => {
                                            const delimiter = index + 1 === item.offices.length ? '' : '/'
                                            return (<>
                                                {office.name.replace('Svenska Mäklarhuset', '')}{delimiter}
                                            </>)
                                        })}
                                    </strong>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <div><span>{item.activityTranslated.tips_sent}</span></div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            );
        case 'agent_tips_leader_board':
            return (
                <>
                    {data.map((item, i) => {
                        return (
                            <tr key={i} data-agentid={item.id} className={item.id === currentUser.id ? "loggedInAgent" : ""}>
                                <td className="number">{i + 1}</td>
                                <td className="image">
                                    <div className="person-image" style={{ backgroundImage: "url(" + item.image + ")" }}></div>
                                </td>
                                <td className="person">
                                    {item.name}
                                </td>
                                <td className="tips_annual">{item.activityTranslated.tips_sent}</td>
                            </tr>
                        )
                    })}
                </>
            );
        case 'agents_rates':
            return (
                <>
                    {data.map((item, i) => {
                        return (
                            <tr key={i} data-agentid={item.id} className={item.id === currentUser.id ? "loggedInAgent" : ""}>
                                <td className="number">{i + 1}</td>
                                <td className="image">
                                    <div className="person-image" style={{ backgroundImage: "url(" + item.image + ")" }}></div>
                                </td>
                                <td className="person">
                                    {item.name}
                                    <strong className="person-office">
                                        {item.offices.map((office, index) => {
                                            const delimiter = index + 1 === item.offices.length ? '' : '/'
                                            return (<>
                                                {office.name.replace('Svenska Mäklarhuset', '')}{delimiter}
                                            </>)
                                        })}
                                    </strong>
                                </td>
                                <td>
                                    <div><span>{item.nps}</span></div>
                                </td>
                                <td>
                                    <div><span>{item.testimonials}</span></div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            );
        default:
            return '';
    }
}

function numberWithSpaces(x) {
    if (!x) {
        return false;
    }
    x = parseInt(x);

    let parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0");
    return parts.join(".");
}

export default Table;
